/**
 * ui-router states config
 */
app.config([
  '$routeProvider',
  '$stateProvider',
  '$urlServiceProvider',
  '$locationProvider',
  function (
    $routeProvider,
    $stateProvider,
    $urlServiceProvider,
    $locationProvider
  ) {
    // AngularTranslate resolver => avoid Flash of Untranslated Content
    const resolve = {
      translateReady: ['$translate', $translate => $translate.onReady()]
    };

    $locationProvider.hashPrefix('');

    var homeState = {
      name: 'crm',
      url: '/',
      templateUrl: 'blocks/crm/views/crm.html',
      controller: 'CrmCtrl as vm',
      abstract: true
    };

    var inboxState = {
      name: AppState.INBOX_STATE,
      url: 'inbox/',
      views: {
        customView: {
          template: '<columns></columns>'
        }
      },
      resolve
    };

    var caseState = {
      name: AppState.CASE_STATE,
      url: 'case/:id/?sideviewmenuoption',
      params: {
        id: {type: 'string'},
        sideviewmenuoption: {type: 'string'}
      },
      resolve
    };

    var contacsState = {
      name: AppState.CONTACTS_STATE,
      url: 'contact_agenda/',
      views: {
        customView: {
          templateUrl:
            'blocks/crm/contact-agenda/views/contact_agenda_wrapper.html'
        }
      },
      resolve
    };

    var contactAgendaNewState = {
      name: 'contact_agenda_new',
      url: '/contact_agenda_new/',
      params: {vue_frame_name: 'contact_agenda_new'},
      templateUrl: 'blocks/crm/contact-agenda/views/contact_agenda_wrapper_new.html',
      controller: 'ContactAgendaNewCtrl',
      controllerAs: 'vm',
      resolve
    };

    var onboardingState = {
      name: 'onboarding',
      url: '/onboarding/',
      params: {vue_frame_name: 'onboarding'},
      templateUrl: 'blocks/login_splash/views/vue_iframe.html',
      controller: 'OnboardingCtrl',
      controllerAs: 'vm',
      resolve
    };

    var loginState = {
      name: AppState.LOGIN_STATE,
      url: '/login/',
      templateUrl: 'blocks/login/views/login.html',
      controller: 'LoginCtrl',
      controllerAs: 'vm',
      resolve
    };

    var verifyEmailState = {
      name: 'verify email',
      url: '/verify-email/:userId/:verificationToken/',
      templateUrl: 'blocks/verify_email/views/verify_email.html',
      controller: 'EmailVerificationCtrl',
      resolve
    };

    var signupState = {
      name: 'signup',
      url: '/signup/',
      templateUrl: 'blocks/signup/views/signup.html',
      controller: 'SignupCtrl',
      resolve
    };

    var forgottenPasswordState = {
      name: 'forgotten password',
      url: '/forgotten_password/',
      templateUrl: 'blocks/forgotten_password/views/forgotten_password.html',
      controller: 'ForgottenPasswordCtrl',
      resolve
    };

    var forgottenPasswordTokenState = {
      name: 'forgotten password token',
      url: '/forgotten_password/:token/',
      resolve: {
        redirect: [
          '$window',
          '$stateParams',
          'FRONTEND_VUE_URL',
          function ($window, $stateParams, FRONTEND_VUE_URL) {
            const token = $stateParams.token;
            if (token) {
              $window.location.href = `${FRONTEND_VUE_URL}/forgotten_password/${token}/`;
            }
          }
        ]
      }
    };

    var playgroundState = {
      name: 'playground',
      url: '/playground/',
      templateUrl: 'blocks/playground/playground-view.html',
      controller: 'PlaygroundCtrl',
      controllerAs: 'vm',
      resolve
    };

    var messagesTesterState = {
      name: 'message_tester',
      url: '/messages/tester/',
      templateUrl: 'blocks/tester/views/tester.html',
      controller: 'MessagesTesterCtrl',
      controllerAs: 'vm',
      resolve
    };

    var installState = {
      name: AppState.INSTALL_STATE,
      url: '/install/app?install&code',
      templateUrl: 'blocks/install/views/install.html',
      controller: 'InstallCtrl',
      controllerAs: 'vm',
      resolve
    };

    $stateProvider.state(homeState);
    $stateProvider.state(inboxState);
    $stateProvider.state(caseState);
    $stateProvider.state(contacsState);
    $stateProvider.state(contactAgendaNewState);
    $stateProvider.state(onboardingState);
    $stateProvider.state(loginState);
    $stateProvider.state(verifyEmailState);
    $stateProvider.state(signupState);
    $stateProvider.state(forgottenPasswordState);
    $stateProvider.state(forgottenPasswordTokenState);
    $stateProvider.state(playgroundState);
    $stateProvider.state(messagesTesterState);
    $stateProvider.state(installState);

    $urlServiceProvider.rules.when('/admin/', [
      '$state',
      'AppStateService',
      'UserService',
      function (
        $state,
        AppStateService: AppStateService,
        UserService: UserService
      ) {
        UserService.getProfile().then(user => {
          if (user.isManager) {
            if (user.isAdmin) {
              $state.go('admin.dashboard');
            } else if (user.isAnalyst) {
              $state.go('admin.export');
            } else if (user.isSupervisor) {
              $state.go('admin.assignments');
            }
          } else {
            AppStateService.goToInbox();
          }
        });
      }
    ]);
    $urlServiceProvider.rules.when('/case/:id', '/inbox/case/:id/');
    $urlServiceProvider.rules.when('/case/:id/', '/inbox/case/:id/');
    $urlServiceProvider.rules.when('/inbox/case/:id', '/inbox/case/:id/');
    $urlServiceProvider.rules.otherwise('/inbox/');
  }
]);
